<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
          <h4 class="card-title">{{ $t('BarcReport.fund_dist_repot') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
              >
              <flat-pickr class="form-control"
                  v-model="search.from_date"
                  :placeholder="$t('globalTrans.select_date')"
              ></flat-pickr>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              label-cols-md="4"
              :label="$t('globalTrans.to_date')"
              label-for="to_date"
              >
              <flat-pickr class="form-control"
                          v-model="search.to_date"
                          :placeholder="$t('globalTrans.select_date')"
              ></flat-pickr>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6">
              <b-form-group
                  class="row"
                  label-cols-sm="3"
                  label-cols-md="4"
                  label-for="warehouse_id"
                  :label="$t('grantDistribution.fund_sub_head')"
              >
                  <b-form-select
                      plain
                      v-model="search.fund_sub_head_id"
                      :options="fundSubHeadList"
                      id="warehouse_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
              </b-form-group>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.submit')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row v-show="showData">
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('BarcReport.fund_dist_repot') }}</h4>
          </template>
          <template v-slot:headerAction>
              <b-button class="btn_add_new mr-2" @click="pdfExport">
                <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
              </b-button>
              <export-excel
                class="btn btn_add_new"
                :data="excelData"
                :title="headerValue"
                worksheet="Report Sheet"
                :default-value="headerExcelDefault"
                name="fund-distributution-report.xls">
                <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
              </export-excel>
            </template>
          <template v-slot:body>
            <b-overlay :show="loading">
                <b-row v-show="!loading">
                  <b-col>
                    <list-report-head :base-url="incentiveGrantServiceBaseUrl" uri="/common-config/report-heading/detail" :org-id="4">
                      {{ $t('BarcReport.fund_dist_repot')}}
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-row>
                    <b-col></b-col>
                </b-row>
              <b-row mt-5>
                <b-col md="12" class="table-responsive">
                  <div>
                      <b-table-simple bordered hover small caption-top responsive>
                        <b-thead>
                          <b-tr>
                            <b-th style="">{{ $t('globalTrans.sl_no') }}</b-th>
                            <b-th style="">{{ $t('grantDistribution.applicant_id') }}</b-th>
                            <b-th style="">{{ $t('grantDistribution.applicant_name') }}</b-th>
                            <b-th >{{ $t('admission_form.email') }}</b-th>
                            <b-th >{{ $t('admission_form.mobile_no') }}</b-th>
                            <b-th >{{ $t('grantDistribution.payment_date') }}</b-th>
                            <b-th >{{ $t('grantDistribution.fund_sub_head') }}</b-th>
                            <b-th >{{ $t('BarcReport.pay_amount') }}</b-th>
                          </b-tr>
                          <b-tr  v-for="(info, index) in reportData" :key="index">
                            <b-td> {{ $n(index + 1) }} </b-td>
                            <b-td> {{ $n(info.app_gen_id, { useGrouping: false }) }} </b-td>
                            <b-td> {{ ($i18n.locale === 'bn') ? info.name_bn : info.name }} </b-td>
                            <b-td> {{ info.email }} </b-td>
                            <b-td v-if="(info.mobile_no !== null)"> {{ info.mobile_no | mobileNumber}} </b-td>
                            <b-td v-else> {{ ' ' }} </b-td>
                            <b-td> {{ info.payment_date | dateFormat }} </b-td>
                            <b-td> {{ ($i18n.locale === 'bn') ? info.head_bn : info.head_en }} </b-td>
                            <b-td> {{ $n(info.pay_amount) }} </b-td>
                          </b-tr>
                        </b-thead>
                      </b-table-simple>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { fundDistributionApi } from '../../api/routes'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import { dateFormat, mobileNumber } from '@/Utils/fliter'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

export default {
  components: { ListReportHead },
  data () {
    return {
      showData: false,
      showHeading: false,
      search: {
        org_id: 4,
        fund_sub_head_id: 0,
        from_date: '',
        to_date: ''
      },
      tempList: [],
      rows: [],
      reportData: [],
      loading: false,
      headerExcelDefault: {
        orgName: null,
        orgNameBn: null,
        address: null,
        address_bn: null
      },
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl
    }
  },
  computed: {
    fundSubHeadList: function () {
      const objectData = this.$store.state.incentiveGrant.commonObj.fundSubHeadList.filter(item => item.status === 1)
      return objectData.map(obj => {
          if (this.$i18n.locale === 'bn') {
              return { value: obj.value, text: obj.text_bn }
          } else {
              return { value: obj.value, text: obj.text_en }
          }
      })
    },
    headerValue: function () {
      const headerVal = []
      if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('BarcReport.grant_application_status_report')
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('BarcReport.grant_application_status_report')
        }
        return headerVal
      },
      excelData: function () {
        const listData = this.reportData
        var serial = 0
        const listContractor = listData.map(keyItem => {
          serial++
           if (this.$i18n.locale === 'en') {
              return {
                'sl ': serial,
                'Applicants ID': keyItem.app_gen_id,
                'Applicants Name': keyItem.name,
                'Email ': keyItem.email,
                'Mobile Number': keyItem.mobile_no !== null ? +'0' + keyItem.mobile_no : ' ',
                'Payment Date ': keyItem.payment_date !== null ? keyItem.payment_date : ' ',
                'Fund Sub Head': keyItem.head_bn,
                'Pay Amount': keyItem.pay_amount
              }
            } else {
              return {
                'ক্রমিক সংখ্যা': this.$n(serial),
                'আবেদনকারীদের আইডি ': this.$n(keyItem.app_gen_id, { useGrouping: false }),
                'আবেদনকারীর নাম': keyItem.name_bn,
                'ইমেল ': keyItem.email,
                'মোবাইল নম্বর': mobileNumber(keyItem.mobile_no),
                'টাকা প্রদানের তারিখ ': dateFormat(keyItem.payment_date),
                'তহবিলের খাত': keyItem.head_en,
                'অর্থের পরিমাণ ': this.$n(keyItem.pay_amount, { useGrouping: false })
              }
            }
        })
        return listContractor
      }
  },
  watch: {
  },
  created () {
  },
  mounted () {
    core.index()
    this.headerDataExcel()
  },
  methods: {
    headerDataExcel () {
        RestApi.getData(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail/4').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 4)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
    },
    pdfExport () {
        const reportTitle = this.$t('BarcReport.fund_dist_repot')
        ExportPdf.exportPdfDetails(incentiveGrantServiceBaseUrl, '/common-config/report-heading/detail', 4, reportTitle, this.reportData, this)
    },
    searchData () {
      this.loadData()
      this.showData = true
      this.showHeading = true
    },
    async loadData () {
      this.loading = true
      const result = await RestApi.getData(incentiveGrantServiceBaseUrl, fundDistributionApi, this.search)
      if (result.success) {
        // this.reportData = result.data.data
        this.reportData = this.getRelationalData(result.data.data)
        // this.paginationData(result.data)
      } else {
        this.reportData = []
      }
      this.loading = false
    },
    getRelationalData (data) {
        const orgList = this.$store.state.commonObj.organizationProfileList
        const headList = this.$store.state.incentiveGrant.commonObj.fundSubHeadList
         const listData = data.map(item => {
              const orgData = orgList.find(organization => organization.value === item.can_org_id)
              const headData = headList.find(head => head.value === item.fund_sub_head_id)
              const orgObj = {
                organization_en: orgData !== undefined ? orgData.text_en : '',
                organization_bn: orgData !== undefined ? orgData.text_bn : ''
              }
              const headObj = {
                head_en: headData !== undefined ? headData.text_en : '',
                head_bn: headData !== undefined ? headData.text_bn : ''
              }
              return Object.assign({}, item, orgObj, headObj)
          })
          return listData
    }
  }
}
</script>
<style scoped>
 .container {
   display: flex;
   margin-bottom: 15px;
   justify-content: center;
 }
 .report-name {
   text-align: center;
 }
 .org-name {
  text-align: center;
 }
 .org-address {
   text-align: center;
 }
 .main-title {
   padding: 10px;
 }
 .project-name {
   text-align: center;
   font-weight: bold;
 }
</style>
